import React from 'react';
import { Link } from 'gatsby';

export default function BlogList({ blogPosts }) {
  return (
    <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="absolute inset-0">
        <div className="bg-white h-1/3 sm:h-2/3" />
      </div>
      <div className="relative max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
            Altijd op de hoogte
          </h2>
          <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
            Wekelijks komen wij met een nieuw blog over diverse bouw-gerelateerde onderwerpen.
          </p>
        </div>
        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {blogPosts.map((post) => (
            <div key={post.node.id} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <Link to={`/blog/${post.node.slug}`}>
                  <img
                    className="h-48 w-full object-cover"
                    src={post.node.heroImage.gatsbyImageData.images.fallback.src}
                    alt=""
                  />
                </Link>
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-secondary">
                    <Link
                      to={`/blog/${post.node.slug}`}
                      className="text-xl text-secondary font-bold hover:underline"
                    >
                      {post.node.title}
                    </Link>
                  </p>
                  <a href={post.href} className="block mt-2">
                    <p className="text-xl font-semibold text-gray-900">{post.title}</p>
                    <p className="mt-3 text-base text-gray-500">
                      {post.node.body.childMarkdownRemark.excerpt}
                    </p>
                  </a>
                </div>
                <div className="mt-6 flex items-center">
                  <div className="flex-shrink-0">
                    <span className="sr-only">{post.node.author.name}</span>
                  </div>
                  <div className="">
                    <p className="text-sm font-medium text-primary">{post.node.author.name}</p>
                    {/* <div className="flex space-x-1 text-sm text-gray-500"> */}
                    {/*  <time dateTime={post.node.publishDate}>{post.node.publishDate}</time> */}
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
