import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import BlogList from '../components/BlogList';
import { SEO } from '../components/SEO/SEO';

const BlogPage = ({ data, location }) => {
  return (
    <Layout>
      <SEO location={location} pageMetadata={{ title: 'Blog' }} />
      <BlogList blogPosts={data.allContentfulBlogPost.edges} />
    </Layout>
  );
};

export default BlogPage;

export const pageQuery = graphql`
  query BlogIndexQuery {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          id
          title
          slug
          publishDate(formatString: "DD MMMM, YYYY", locale: "nl")
          body {
            childMarkdownRemark {
              excerpt
              longExcerpt: excerpt(pruneLength: 260)
            }
          }

          heroImage {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          author {
            name
            image {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          category {
            id
            name
            slug
          }
        }
      }
    }
  }
`;
